import React from 'react';
import Layout from '../../layouts/layout';
import Accordion from '../../components/Accordion/Accordion';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import WavingHand from '../../components/WavingHand/WavingHand';
import JOIN_1 from '../../images/join-1.jpg';
import JOIN_2 from '../../images/join-2.jpg';
import JOIN_3 from '../../images/join-3.jpg';
import JOIN_4 from '../../images/join-4.jpg';
import ICON_SPIRIT from '../../images/icon-spirit.png';
import ICON_SKILL from '../../images/icon-skill.png';
import ICON_FUN from '../../images/icon-fun.png';
import ICON_HAPPINESS from '../../images/icon-happiness.png';
import './join.scss';

const Join = (): JSX.Element => (
  <Layout title="Join Us" path="join">
    <div className="join">
      <Header />
      <div className="join-container">
        <div className="join-heading">
          <h1 data-sal="slide-up" data-sal-duration="600">
            JOIN US
          </h1>
          <div className="join-heading-sub">
            <h2 data-sal="slide-up" data-sal-duration="600">
              We’re about a mixture of science, logic and emotions.
            </h2>
            <p data-sal="slide-up" data-sal-duration="600">
              Keep an open mind, have a thirst for novelty, explore the field of possibilities and reinvent yourself
              with each project.
            </p>
          </div>
        </div>
        <div className="join-images">
          <img src={JOIN_1} alt="" />
          <img src={JOIN_2} alt="" />
          <img data-sal="zoom" data-sal-duration="300" src={JOIN_3} alt="" />
          <img data-sal="zoom" data-sal-duration="300" src={JOIN_4} alt="" />
        </div>
        <div className="home-section-table">
          <div className="home-section-row" data-sal="slide-up" data-sal-duration="600">
            <div className="home-section-cell mod-img">
              <img src={ICON_SPIRIT} alt="" />
            </div>
            <div className="home-section-cell mod-text">
              <h2>Spirit</h2>
            </div>
            <div className="home-section-cell mod-img">
              <img src={ICON_SKILL} alt="" />
            </div>
            <div className="home-section-cell mod-text">
              <h2>Skill</h2>
            </div>
          </div>
          <div className="home-section-row" data-sal="slide-up" data-sal-duration="600">
            <div className="home-section-cell mod-empty" />
            <div className="home-section-cell mod-text">
              <p>A combination of thought and ideas. We come up with solutions that are original and ingenious.</p>
            </div>
            <div className="home-section-cell mod-empty" />
            <div className="home-section-cell mod-text">
              <p>The ability to do something well; expertise.</p>
            </div>
          </div>
          <div className="home-section-row" data-sal="slide-up" data-sal-duration="600">
            <div className="home-section-cell mod-img">
              <img src={ICON_FUN} alt="" />
            </div>
            <div className="home-section-cell mod-text">
              <h2>Fun</h2>
            </div>
            <div className="home-section-cell mod-img">
              <img src={ICON_HAPPINESS} alt="" />
            </div>
            <div className="home-section-cell mod-text">
              <h2>Happiness</h2>
            </div>
          </div>
          <div className="home-section-row" data-sal="slide-up" data-sal-duration="600">
            <div className="home-section-cell mod-empty" />
            <div className="home-section-cell mod-text">
              <p>“Making work fun” is our motto.</p>
            </div>
            <div className="home-section-cell mod-empty" />
            <div className="home-section-cell mod-text">
              <p>Our group places great importance on goodwill towards one another.</p>
            </div>
          </div>
        </div>
        <div className="join-table-mobile">
          <div data-sal="slide-up" data-sal-duration="600" className="join-table-cell">
            <img src={ICON_SPIRIT} alt="" />
            <div className="join-table-cell-text">
              <h3>Spirit</h3>
              <p>A combination of thought and ideas. We come up with solutions that are original and ingenious.</p>
            </div>
          </div>
          <div data-sal="slide-up" data-sal-duration="600" className="join-table-cell">
            <img src={ICON_SKILL} alt="" />
            <div className="join-table-cell-text">
              <h3>Skill</h3>
              <p>The ability to do something well; expertise.</p>
            </div>
          </div>
          <div data-sal="slide-up" data-sal-duration="600" className="join-table-cell">
            <img src={ICON_FUN} alt="" />
            <div className="join-table-cell-text">
              <h3>Fun</h3>
              <p>“Making work fun” is our motto.</p>
            </div>
          </div>
          <div data-sal="slide-up" data-sal-duration="600" className="join-table-cell">
            <img src={ICON_HAPPINESS} alt="" />
            <div className="join-table-cell-text">
              <h3>Happiness</h3>
              <p>Our group places great importance on goodwill towards one another.</p>
            </div>
          </div>
        </div>

        <div className="join-posting">
          <h1 data-sal="slide-up" data-sal-duration="600">
            JOB OPENINGS
          </h1>
          <p data-sal="slide-up" data-sal-duration="600">
            Here are our latest openings. Looking forward to hearing from you!
          </p>
          <div className="join-accordions">
            <Accordion title="Frontend Engineer">
              <div>
                We are looking for an experienced Cloud Application Engineer for an exciting cloud migration project! If
                you meet the following requirements, shoot us a message!
                <br />
                <ul>
                  <li>
                    Hands-on experience architecting, designing, implementing, and supporting cloud-based applications
                    and solutions.
                  </li>
                  <li>
                    Knowledge of common application design patterns (e.g. MVP, three-tier), middleware, database, and
                    integration technologies (e.g. Apache, Nginx, SQL Server, MySQL, File Transfer, Messaging, API,
                    etc.).
                  </li>
                  <li>
                    Experience with AWS and/or Azure environments, Infrastructure as a Service (IaaS), Platform as a
                    Service (PaaS) and hybrid cloud are required.
                  </li>
                  <li>
                    Hands-on experience in all aspects of cloud computing (compute, CI/CD, containers, storage,
                    platforms, data, networking and security)
                  </li>
                  <li>
                    Knowledge in cloud monitoring tools, application performance monitoring tools, operational
                    dash-boarding is required.
                  </li>
                  <li>
                    Knowledge in networking and web standards such as DNS, DHCP, TCP/IP, HTTP, web security, switches,
                    routers, load balancers, firewalls is required.
                  </li>
                  <li>Proficient in NodeJS</li>
                </ul>
              </div>
            </Accordion>
            <Accordion title="Software Engineer (Backend)">
              <div>
                We are a newly established software development team. Our team consists of UI Designer, UX Designer,
                Project managers, Frontend engineer, and Backend Engineers. We are passionate in building quality
                product for our clients that's why we put lots of effort in testing. We are product minded that's why we
                care so much on usability and user's behavior.
                <br />
                <br />
                We are now recruiting a backend software engineer. It is a new position. Skills in Nodejs, Typescript
                are required. Knowledge in MongoDB, Azure SQL, experiences in AWS or MS Azure would be highly
                preferable.
                <br />
                <br />
                Passionate team and good vibe, join us and build something great!
              </div>
            </Accordion>
            <Accordion title="Software Architect (AWS / MS Azure)">
              <div>
                We are looking for an experienced Cloud Application Engineer for an exciting cloud migration project! If
                you meet the following requirements, shoot us a message!
                <br />
                <ul>
                  <li>
                    Hands-on experience architecting, designing, implementing, and supporting cloud-based applications
                    and solutions.
                  </li>
                  <li>
                    Knowledge of common application design patterns (e.g. MVP, three-tier), middleware, database, and
                    integration technologies (e.g. Apache, Nginx, SQL Server, MySQL, File Transfer, Messaging, API,
                    etc.).
                  </li>
                  <li>
                    Experience with AWS and/or Azure environments, Infrastructure as a Service (IaaS), Platform as a
                    Service (PaaS) and hybrid cloud are required.
                  </li>
                  <li>
                    Hands-on experience in all aspects of cloud computing (compute, CI/CD, containers, storage,
                    platforms, data, networking and security)
                  </li>
                  <li>
                    Knowledge in cloud monitoring tools, application performance monitoring tools, operational
                    dash-boarding is required.
                  </li>
                  <li>
                    Knowledge in networking and web standards such as DNS, DHCP, TCP/IP, HTTP, web security, switches,
                    routers, load balancers, firewalls is required.
                  </li>
                  <li>Proficient in NodeJS</li>
                </ul>
              </div>
            </Accordion>
          </div>
        </div>
      </div>
      <WavingHand />
      <Footer />
    </div>
  </Layout>
);

export default Join;
